import { AppProps } from 'next/app'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { GoogleTagManager } from '@next/third-parties/google'
import { inter, montserrat } from '@ui/fonts'
import { ErrorBoundary } from '@ui/components/ErrorBoundary'

// Load Shared Tailwind CSS
import '@ui/styles/tailwind.css'

function Website({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <AppProviders pageProps={pageProps}>
        <Component {...pageProps} />
      </AppProviders>
    </ErrorBoundary>
  )
}

export interface PageProps {
  [key: string]: unknown
}

interface AppProvidersProps {
  children: React.ReactNode
  pageProps: PageProps
}

const AppProviders: React.FC<AppProvidersProps> = ({ children, pageProps }) => {
  return (
    <>
      <div className={`${inter.variable} ${montserrat.variable} font-inter`}>
        {children}
      </div>
      <Analytics />
      <SpeedInsights />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
    </>
  )
}

export default Website
